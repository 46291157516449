$(document).ready(function(){

    $('.fancybox').fancybox({
        padding: 0,
        openEffect: 'fade',
        closeEffect: 'fade',
        nextEffect: 'fade',
        prevEffect: 'fade',
        wrapCSS: 'custom-style',
        helpers: {
            overlay: {
                locked: false
            }
        },
        beforeShow: function(){
            $("body").css({'overflow-y':'hidden'});
            $("html").addClass('fancybox-margin');
        },
        afterClose: function(){
            $("body").css({'overflow-y':'visible'});
            $("html").removeClass('fancybox-margin');
        }
    });

    var height = screen.height/2;
    $(".fancybox-media").fancybox({
        padding: 0,
        type: "iframe",
        openEffect: 'fade',
        closeEffect: 'fade',
        nextEffect: 'fade',
        prevEffect: 'fade',
        wrapCSS: 'custom-style',
        width : 16/9. * height,
        height : height,
        autoDimensions : false,
        iframe : {
            preload: false
        },
        helpers: {
            overlay: {
                locked: false
            }
        },
        beforeShow: function(){
            $("body").css({'overflow-y':'hidden'});
            $("html").addClass('fancybox-margin');
        },
        afterClose: function(){
            $("body").css({'overflow-y':'visible'});
            $("html").removeClass('fancybox-margin');
        }
    });

    $('#reviews_slider').bxSlider({
        controls: false,
        responsive: true,
        adaptiveHeight: true,
        infiniteLoop: false,
        pagerCustom: '#bx-pager',
        minSlides: 1,
        maxSlides: 1,
        moveSlides: 1,
        slideWidth: 960,
        slideMargin: 10
    });

    $('.get_button').on('click', function(){
        var getB = $(this).parents('.get_b');
        var getWB = $(this).parents('.get_wrap_b');
        var getF = getWB.siblings('.get_forms');
        var id = getB.attr('data-id');
        if(id === 'left'){
            getWB.slideToggle(500);
            getF.find('.get_forms_b.left').slideToggle(500);
        }
        if(id === 'right'){
            getWB.slideToggle(500);
            getF.find('.get_forms_b.right').slideToggle(500);
        }
    });

    $('.get_close').on('click', function(){
        var getFB = $(this).parents('.get_forms_b');
        var getF = getFB.parents('.get_forms');
        var getWB = getF.siblings('.get_wrap_b');
        var id = getFB.attr('data-id');
        if(id === 'left'){
            getWB.slideToggle(500);
            getFB.slideToggle(500);
        }
        if(id === 'right'){
            getWB.slideToggle(500);
            getFB.slideToggle(500);
        }
    });

    $('.js_btn_custom_form').on('click', function(){
        var customHead = $(this).attr('data-head');
        var customPosition = $(this).attr('data-position');
        var customBtnTxt = $(this).attr('data-btn-txt');
        $('.js_custom_head').html(customHead);
        $('.js_custom_btn').val(customBtnTxt);
        $('.js_custom_position').val(customPosition);
    });

    $('input[name="phone"]').inputmask("+7(999)999-99-99");

    $.validator.addMethod('regexp', function(value, element, regexp) {
        var re = new RegExp(regexp);
        return this.optional(element) || re.test(value);
    }, "Некорректно");

    $('.form_vertical, .form_horizontal').each(function () {
        $(this).validate({
            debug: false,
            rules: {
                firstName: {
                    required: true,
                    minlength: 2,
                    regexp: /^[А-ЯЁа-яё\s]*$/
                },
                email: {
                    required: true,
                    email: true
                },
                phone: {
                    required: true,
                    regexp: /^\+\d{1}\(\d{3}\)\d{3}-\d{2}-\d{2}$/
                },
                msg: {
                    required: true,
                    minlength: 10
                }
            },
            messages: {
                firstName: {
                    required: "Введите имя",
                    minlength: $.validator.format( "Не меньше {0} символов" ),
                    regexp: "Только кириллица"
                },
                email: {
                    required: "Введите email",
                    email: "Некорректно"
                },
                phone: {
                    required: "Введите телефон",
                    regexp: "Некорректно"
                },
                msg: {
                    required: "Введите вопрос",
                    minlength: $.validator.format( "Не меньше {0} символов" )
                }
            },
            wrapper: "div",
            errorPlacement: function(error, element) {
                if(element.siblings('.errors_block').length>0){
                    element.siblings('.errors_block').html(error[0].textContent);
                }else{
                    element.after('<span class="errors_block">'+error[0].textContent+'</span>');
                }
            },
            success: function(element) {
                if(element.siblings('.errors_block').length>0){
                    element.siblings('.errors_block').remove();
                }
            },
            submitHandler: function(form) {
                var $form = $(form),
                    $submit = $form.find('input[type=submit]'),
                    submitText = $submit.val();

                $submit.prop('disabled', true).val('Подождите...');
                $.post(
                    $form.attr('action'),
                    $form.serialize(),
                    function(response) {
                        $submit.prop('disabled', false).val(submitText);
                        if(response.redirect) {
                            window.location.href = response.redirect;
                        } else if(response.errorFields && !$.isEmptyObject(response.errorFields)) {
                            for(field in response.errorFields) {
                                if(response.errorFields[field].length > 40) {
                                    alert(response.errorFields[field]);
                                } else {
                                    $form.find('input[name=' + field + ']')
                                        .after('<span class="errors_block">' + response.errorFields[field] + '</span>');
                                }
                            }
                        } else if(response.message) {
                            alert(response.message);
                        }
                    },
                    'json'
                );
            }
        });
    });
});